import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Header from '../components/Header';
import ThemeSwitch from '../components/ThemeSwitch';
import session from '../components/Session';
import CatalogItem from '../components/CatalogItem';
import Slider from '@mui/material/Slider';
import Button from '@mui/material/Button';

import "../styles/catalogmanagement.css";


function Catalog() {

  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  // By default have maxPrice be very high
  const [maxPrice, setMaxPrice] = useState(1000);
  const { sponsor_id } = useParams();

  // Fetch catalog items on page load
  useEffect(() => {
      async function fetchCatalogItems() {
        try {
          const response = await fetch(`${session.getDomain()}/api/catalog/getCatalogItems/${sponsor_id}`);

          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const result = await response.json();
          setItems(result);
          setFilteredItems(result);

        } catch (error) {
          console.error('There was a problem with the fetch operation:', error);
        }
      }
      

      fetchCatalogItems();
    }, []);

    function valuetext(value) {
      return `$${value}`;
    }

    function updateMaxPrice(event, value){
      setMaxPrice(value);
    }

    function applyPriceFilter() {
      setFilteredItems(items.filter(item => item.price <= maxPrice));
    }




    return (
      <>
          <Header />
          <h1 style={{ textAlign: "center", color: "black", textShadow: "1px 1px 0 white, -1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white" }}>Catalog</h1>
          <h3 style={{ textAlign: "center", color: "black", textShadow: "1px 1px 0 white, -1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white" }}>Price Filter</h3>
          <div style={{ width: '80%', maxWidth: '600px', margin: '0 auto' }}>
            <Slider
              aria-label="Max Price"
              defaultValue={1000}
              getAriaValueText={valuetext}
              valueLabelDisplay="auto"
              step={5}
              marks
              min={10}
              max={110}
              onChange={updateMaxPrice}
            />
            <Button variant="contained" onClick={applyPriceFilter}>Apply Filter</Button>
          </div>
          <div id='catalogContainer' className='display-side-by-side'>
                  {filteredItems.length > 0 && (
                        <ul>
                        {filteredItems.map((item, index) => (
                            <CatalogItem key={index} item={item} isDriver={true} showPoints={session.getUser()._accountType == 1} />
                        ))}
                        </ul>
                  )}
          </div>
      </>
    );
}

export default Catalog;