import React, { useState, useEffect } from 'react';
import ThemeSwitch from './ThemeSwitch';
import { Link } from 'react-router-dom';
import Session from './Session';
import Points from '../assets/PointIcon.png';
import Logo from '../assets/TruckStopNShop_Logo.png'
import PointsNotification from '../assets/PointIconNotification.png';

function Header() {
  const [pointsDropdown, setPointsDropdown] = useState(false);
  const [userPoints, setUserPoints] = useState(0);
  const [shoppingCart, setShoppingCart] = useState([]);
  const [prevPoints, setPrevPoints] = useState(0);
  const [icon, setIcon] = useState(Points); // Default points icon

  const fetchPoints = async () => {
    const userId = Session.getUser()?._userId;
    const userClass = Session.getUser()?._accountType;

    if (userId && userClass) {
      try {
        const response = await fetch(`${Session.getDomain()}/api/profile?userId=${encodeURIComponent(userId)}&userClass=${encodeURIComponent(userClass)}`);
        const data = await response.json();

        if (data.found === false) {
          alert('Profile not found.');
        } else {
          setUserPoints(data.driver_points || 0);

          if (prevPoints !== (data.driver_points || 0)) {
            setIcon(PointsNotification);
          } else {
            setIcon(Points);
          }

          setPrevPoints(data.driver_points || 0);
        }
      } catch (e) {
        console.error("Error fetching points:", e);
      }
    }
  };

  useEffect(() => {
    CheckLoggedToken();
    setShoppingCart(Session.getShoppingCart().Cart);
    fetchPoints();
  }, []);




  async function CheckLoggedToken() {
    if (getCookie("loggedCookie") == false && Session.getLoggedIn() == "true") {
      Session.endSession();
    }
  }


  function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return false;
  }


  const RemoveFromCart = async (item, event) => {
    event.stopPropagation();
    const tempCart = Session.getShoppingCart();
    const itemIndex = tempCart.Cart.findIndex(cartItem => cartItem.item_name === item);
    if (itemIndex !== -1) {
      tempCart.Cart.splice(itemIndex, 1);
    }
    Session.setShoppingCart(tempCart);
    refreshCart();
  };

  function refreshCart() {
    setShoppingCart(Session.getShoppingCart().Cart);
  }

  const togglePointsDropdown = () => {
    setPointsDropdown(!pointsDropdown);
    setIcon(Points);

    if (!pointsDropdown) {
      fetchPoints(); // Fetch points when dropdown is opened
    }
  };

  return (
    <>
      <ThemeSwitch />
      <header className="p-3 mb-3">
        <div className="container">
          <div className="row align-items-center justify-content-between">

            {/* Points Icon and Logo Wrapper */}
            <div className="col-6 d-flex align-items-center">
              {/* Points Icon */}
              <div className="me-3">
                <div className="dropdown text-end">
                  <a href="#" onClick={togglePointsDropdown} className="d-block link-body-emphasis text-decoration-none dropdown-toggle" aria-expanded={pointsDropdown}>
                    <img src={icon} alt="Points Icon" width="50" height="50" className="rounded-circle" />
                  </a>
                  {pointsDropdown && (
                    <ul className="dropdown-menu text-small show">
                      <li><a className="dropdown-item">Current Points: {userPoints}</a></li>
                    </ul>
                  )}
                </div>
              </div>

              {/* Logo */}
              <div>
                <a href="/" className="main-logo d-flex align-items-center link-body-emphasis text-decoration-none">
                  <img src={Logo} alt="Truck Stop 'N' Shop Logo" className="logo-image" style={{ width: "360px", height: "auto" }}/>
                </a>
              </div>
            </div>
          </div>

          {/* Navigation Links and Profile Picture in the same row */}
          <div className="row mt-2 align-items-center justify-content-between">
            <ul className="nav col-lg-auto mb-md-0">
              <li><Link className="nav-link px-2 link-secondary" to="/">Home</Link></li>
              {Session.getLoggedIn() && Session.getDriverUser() != null && (<li><Link className="nav-link px-2 link-body-emphasis" to={`/Catalog/${Session.getDriverUser()._sponsorId}`}>Catalog</Link></li>)}
              <li><Link className="nav-link px-2 link-body-emphasis" to="/About">About</Link></li>
              {!Session.getLoggedIn() && (<li><Link className="nav-link px-2 link-body-emphasis" to="/signin">Sign In</Link></li>)}
              {!Session.getLoggedIn() && (<li><Link className="nav-link px-2 link-body-emphasis" to="/AccountCreation">Create Account</Link></li>)}
              <li><Link className="nav-link px-2 link-body-emphasis" to="/Support">Support</Link></li>

              {Session.getUser()?._accountType === 0 && (
                <li><Link className="nav-link px-2 link-body-emphasis" to="/driverApplications">Apply</Link></li>
              )}

              {Session.getUser()?._accountType === 3 && (
                <li><Link className="nav-link px-2 link-body-emphasis" to="/admin">Admin</Link></li>
              )}

              {Session.getSponsorUser() != null && (
                <>
                  <li><Link className="nav-link px-2 link-body-emphasis" to={`/admin/sponsors/${Session.getSponsorUser()?._sponsorId}`}>Sponsor (OLD)</Link></li>
                  <li><Link className="nav-link px-2 link-body-emphasis" to={`/manage/sponsor/${Session.getSponsorUser()?._sponsorId}`}>Sponsor (NEW)</Link></li>
                </>
              )}
            </ul>

            {/* Profile Picture and Username aligned to the right */}
            {Session.getLoggedIn() && (
              <div className="col-lg-auto text-end d-flex align-items-center">

                <div className="dropdown text-end">
                  <a href="#" className="d-block link-body-emphasis text-decoration-none dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                    <i onClick={refreshCart} style={{ fontSize: "50px", color: "white", marginRight: '5px' }} className="las la-shopping-cart"></i>
                  </a>

                  <ul style={{ maxHeight: "500px", width: "400px", overflow: "auto" }} className="dropdown-menu text-small">
                    {shoppingCart.length > 0 ? (
                      <>
                        {shoppingCart.map((item) => (
                          <li className='cartItems' key={item.catalog_item_id}>
                            <div className="CatalogItem">
                              <span className="item-text">
                                <strong>{item.price} Points</strong><br />  {item.item_name}
                              </span>
                              <img className="item-image" src={item.image_url} alt={item.item_name} />
                              <a onClick={(event) => RemoveFromCart(item.item_name, event)} href="#"> <span className="material-symbols-outlined">close</span> </a>
                            </div>
                          </li>
                        ))}
                        <li className='cartItems' style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                          <Link className="btn btn-primary" to="/checkout">Checkout</Link>
                        </li>
                      </>
                    ) : (
                      <li style={{ display: "flex", justifyContent: "center", width: "100%" }} className='cartItems'>
                        Empty
                      </li>
                    )}
                  </ul>
                </div>

                <h4 style={{ color: 'white', marginRight: '10px' }}>{Session.getUser() ? Session.getUser()._username : ""}</h4>
                <div className="dropdown text-end">
                  <a href="#" className="d-block link-body-emphasis text-decoration-none dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                    <img src={Session.getUser()._profilePicture ? Session.getUser()._profilePicture : "https://github.com/mdo.png"} alt="Profile" width="50" height="50" className="rounded-circle" />
                  </a>
                  <ul className="dropdown-menu text-small">
                    <li><Link className="dropdown-item" to="/OrderHistory">Order history</Link></li>
                    <li><a className="dropdown-item" href="#">Settings</a></li>
                    <li><Link className="dropdown-item" to="/Profile">Profile</Link></li>
                    <li><Link className="dropdown-item" to="/Inbox">Inbox</Link></li>
                    <li><hr className="dropdown-divider" /></li>
                    <li><a onClick={() => { Session.endSession(); }} className="dropdown-item" href="#">Sign out</a></li>
                  </ul>
                </div>

              </div>
            )}
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
