import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import Session from '../components/Session';
import './ManageSponsor.css';
import LabeledIconButton from '../components/LabeledIconButton';
import { Modal, Box, Button, Typography } from '@mui/material';

const ManageSponsor = () => {
    const { sponsor_id } = useParams();
    const [sponsor, setSponsor] = useState(null);
    const [showPointValueChangeModal, setShowPointValueChangeModal] = useState(false);

    const pointDollarRef = useRef();

    const navigate = useNavigate();
    useEffect(() => {
        const fetchSponsorDetails = async () => {
            try {
                const response = await fetch(`${Session.getDomain()}/api/admin/sponsors/${sponsor_id}`);
                const data = await response.json();
                setSponsor(data);
            } catch (error) {
                console.error('Error fetching sponsor details:', error);
            }
        };
        fetchSponsorDetails();
    }, [sponsor_id]);

    if (!sponsor) {
        return <div>Loading sponsor details...</div>;
    }

    const placeholderImage = 'https://via.placeholder.com/150';

    // Modal Style
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const handleOpenPointValueChangeModal = () => {
        setShowPointValueChangeModal(true);
    }

    const handleClosePointValueChangeModal = () => {
        setShowPointValueChangeModal(false);
    }

    const handleSubmitPointValueChange = async () => {
        try {
            const response = await fetch(`${Session.getDomain()}/api/admin/sponsors/${sponsor_id}/adjustPointValue`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ newPointValue: pointDollarRef.current.value }),
            });
            const result = await response.json();
            if (result.success) {
                alert("Price updated successfully");
                setShowPointValueChangeModal(false);
                setSponsor((prevSponsor) => ({
                    ...prevSponsor,
                    point_dollar_value: pointDollarRef.current.value,
                }));
            }
            else {
                alert("Error updating price");
            }

        } catch (error) {
            console.error(error);
        }
    }

    return (
        <>
            <Header />
            <div className="sponsor-details-container">
                <h1 className="sponsor-name">{sponsor.sponsor_name}</h1>
                <p className="sponsor-description">
                    {sponsor.sponsor_description || 'No description available.'}
                </p>

                <img
                    src={sponsor.logo ? sponsor.logo : placeholderImage}
                    alt={sponsor.sponsor_name || 'Sponsor Logo'}
                    className="sponsor-logo"
                />
            </div>

            <hr class="spaced-hr"></hr>

            <div className="management-button-container">
                <div className="row">
                    <LabeledIconButton
                        label='Manage Sponsor Users'
                        icon='group'
                        link='./sponsorUsers'
                    >
                    </LabeledIconButton>

                    <LabeledIconButton
                        label='Manage Sponsor Drivers'
                        icon='local_shipping'
                        link='./sponsorDrivers'
                    >
                    </LabeledIconButton>

                    <LabeledIconButton
                        label='View Driver Applications'
                        icon='content_copy'
                        link='./driverApplicationInbox'
                    >
                    </LabeledIconButton>

                    <LabeledIconButton
                        label='Manage Driver Rewards Plans'
                        icon='payments'
                        link='./rewardsPlans'
                    >
                    </LabeledIconButton>

                </div>

                <div className="row">
                    <LabeledIconButton
                        label='View Catalog'
                        icon='local_convenience_store'
                        link={"./catalog/"}
                    >
                    </LabeledIconButton>

                    <LabeledIconButton
                        label='Manage Catalog'
                        icon='library_add'
                        link={"./catalogManagement/"}
                    >
                    </LabeledIconButton>

                    <LabeledIconButton
                        label='Change Point Value'
                        icon='attach_money'
                        onClick={handleOpenPointValueChangeModal}
                    >
                    </LabeledIconButton>



                    <LabeledIconButton
                        label='Driver perspective'
                        icon='search'
                        link={"./DriverPerspective"}
                    >
                    </LabeledIconButton>


                    <Modal
                        open={showPointValueChangeModal}
                        onClose={handleClosePointValueChangeModal}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style}>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                Update Point Value in USD
                            </Typography>
                            <input
                                type="number"
                                step=".50"
                                placeholder={sponsor.point_dollar_value}
                                ref={pointDollarRef}
                                style={{ width: '100%', padding: '10px', marginTop: '20px' }}
                            />
                            <Button className='submitButton' variant="contained" color="success" onClick={() => {
                                handleSubmitPointValueChange();
                            }}>
                                Submit
                            </Button>
                        </Box>
                    </Modal>

                    <LabeledIconButton
                        label='Audit Logs'
                        icon='folder_open'
                        link='./Report'
                    >
                    </LabeledIconButton>
                </div>

            </div>
        </>
    );
};

export default ManageSponsor;
