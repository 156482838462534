import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import Session from '../components/Session';

function Inbox() {
    const [friends, setFriends] = useState([]);
    const [CurrentName, setCurrentName] = useState();
    const logged = Session.getLoggedIn();

    useEffect(() => {
        listFriend();
    }, []);

    function displayMessages(messages) {
        var here = document.getElementById('here');
        if (here) {
            const list = messages.split("*&&&*");
            const listMessages = list
                .filter(msg => msg !== "")
                .map((msg, index) => `<div key="${index}" class="pastMessages">${msg}</div>`);
            here.innerHTML = listMessages.join('');
        }
    }

    const OpenDM = async (e) => {
        e.preventDefault();
        const form = e.target;
        const formData = new FormData(form);
        const formObject = Object.fromEntries(formData.entries());
        form.reset();
        try {
            const response = await fetch(`${Session.getDomain()}/api/openMessage`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(Object.fromEntries(formData.entries())),
            });
            const result = await response.json();

            if (result.message.inboxid) {
                setCurrentName(formObject.friendName)
                displayMessages(result.message.messages)

            }
            else {
                alert("Username not found")
            }


        } catch (e) {
            console.error('Error:', e);
        }
    };

    /*
        const RefreshDm = async () => {
            try {
                const response = await fetch(`${Session.getDomain()}/api/updateInbox`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({inboxid: currentInbox}),
                });
                const result = await response.json();
                    displayMessages(result.messages)
            
            } catch (e) {
                console.error('Error:', e);
            }
        };
    */
    const openButtonDM = async (friend) => {
        try {
            const response = await fetch(`${Session.getDomain()}/api/openMessage`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    userName: Session.getUser()?._username,
                    friendName: friend,
                }),
            });
            const result = await response.json();
            if (result.message.inboxid) {
                setCurrentName(friend)
                displayMessages(result.message.messages)
            }
        } catch (e) {
            console.error('Error:', e);
        }
    };

    const listFriend = async () => {
        try {
            const response = await fetch(`${Session.getDomain()}/api/returnFriendList`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ userName: Session.getUser()?._username }),
            });
            const result = await response.json();
            setFriends(result);
        } catch (e) {
            console.error('Error:', e);
        }
    };

    const sendDM = async (e) => {
        e.preventDefault();
        if (CurrentName) {
            const form = e.target;
            const formData = new FormData(form);
            const formObject = Object.fromEntries(formData.entries());
            form.reset();

            var encodedMessage = Session.getUser()?._username  + ": " + formObject.message + "*&&&*"

            try {
                const response = await fetch(`${Session.getDomain()}/api/sendMessage`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ userName: Session.getUser()?._username , friendName: CurrentName, message: encodedMessage }),
                });
                const result = await response.json();
                openButtonDM(CurrentName);
            } catch (e) {
                console.error('Error:', e);
            }
        }

    };


    return (
        <>
            { logged === false && (window.location.href = '/')}
            <Header />
            <div id="inboxContainer">

                <div id="messagesBox">

                    <form onSubmit={OpenDM}>
                        <input
                            type="text"
                            name="friendName"
                            autoCorrect="off"
                            placeholder="User Name" autoComplete='off'
                        />
                        <input
                            type="hidden"
                            value={Session.getUser()?._username }
                            name="userName"
                            autoCorrect="off"
                        />
                        <button>Search User</button>
                    </form>
                    <div id="peopleHere">
                        {friends.length === 0 ? (
                            <button className="people"> Looking empty</button>
                        ) : (
                            friends.map((friend, index) => (
                                <button
                                    key={index}
                                    className="people"
                                    onClick={() => openButtonDM(friend.friend_name)}
                                >
                                    {friend.friend_name}
                                </button>
                            ))
                        )}
                    </div>
                </div>
                <div id="openedMessage">
                    <h1>{CurrentName ? CurrentName : "Nobody"}</h1>

                    <div id="here"><div className="pastMessages">Start a conversation</div></div>

                    <form onSubmit={sendDM}>
                        <input name='message' autoComplete='off' type="text" placeholder='Message' />
                        <button>Send</button>
                    </form>
                </div>
            </div>
        </>
    );
}

export default Inbox;
