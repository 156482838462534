import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable'
import { useParams, useNavigate } from 'react-router-dom';
import Session from '../components/Session';

function AdminReport() {

    var DriverInfo = [];
    var userInfo = [];

    useEffect(() => {
        document.getElementById('pdfFrame').srcdoc= "<h1 style='text-align:center;' style=fontSize: '1px' >Load Audit</h1>";
    });


    async function fetchDriverDetails() {
        try {
            const response = await fetch(`${Session.getDomain()}/api/getuserdata/adminDriverReport`);
            const data = await response.json();
            DriverInfo = await data.Drivers;
        } catch (error) {
            console.error('Error fetching details:', error);
        }

        var doc = new jsPDF({ putOnlyUsedFonts: true });
        doc.setFontSize(20);
        doc.text("Driver Report", doc.internal.pageSize.getWidth() / 2 - doc.getTextWidth("Driver Report") / 2, 20);
        doc.setFontSize(12);
        doc.text(`Admin Report Name`, doc.internal.pageSize.getWidth() / 2 - doc.getTextWidth(`Admin Report`) / 2, 30);

        if (DriverInfo) {
            const tableBody = DriverInfo.map(user => [
                user.driver_id,
                user.username,
                user.email,
                user.driver_points,
                user.twofa,
                user.failed_logins,
                user.account_locked,
                user.last_login
            ]);
            autoTable(doc, {
                startY: 40,
                head: [['DriverID', 'Username', 'Email', 'Driver Points', 'MFA', 'Failed Logins', 'Account Locked', 'Last Login']],
                body: tableBody,
            });
        }
        else{
        doc.setFontSize(20);
        doc.text(`No drivers`, doc.internal.pageSize.getWidth() / 2 - doc.getTextWidth(`No drivers`) / 2, 40);
        }
        var pdfData = doc.output("dataurlstring");
        document.getElementById('pdfFrame').removeAttribute("srcdoc");
        document.getElementById('pdfFrame').src = pdfData;

    };

    async function fetchPasswordAudit() {
        try {
            const response = await fetch(`${Session.getDomain()}/api/getuserdata/adminPasswordChangeAudit`);
            const data = await response.json();
            userInfo = await data.users;
        } catch (error) {
            console.error('Error fetching details:', error);
        }

        var doc = new jsPDF({ putOnlyUsedFonts: true });
        doc.setFontSize(20);
        doc.text("Password Change Report", doc.internal.pageSize.getWidth() / 2 - doc.getTextWidth("Password Change Report") / 2, 20);
        doc.setFontSize(12);
        doc.text(`Admin Report`, doc.internal.pageSize.getWidth() / 2 - doc.getTextWidth(`Admin Report`) / 2, 30);

        if (userInfo) {
            const tableBody = userInfo.map(record => [
                record.user_id,
                record.username,
                record.message,
                record.audit_timestamp,
            ]);
            autoTable(doc, {
                startY: 40,
                head: [['User ID', 'Username', 'Audit Message', 'Timestamp']],
                body: tableBody,
            });
        }
        else{
        doc.setFontSize(20);
        doc.text(`No drivers`, doc.internal.pageSize.getWidth() / 2 - doc.getTextWidth(`No drivers`) / 2, 40);
        }
        var pdfData = doc.output("dataurlstring");
        document.getElementById('pdfFrame').removeAttribute("srcdoc");
        document.getElementById('pdfFrame').src = pdfData;

    };

    async function fetchLoginAttemptAudit() {
        try {
            const response = await fetch(`${Session.getDomain()}/api/getuserdata/adminLoginAttemptAudit`);
            const data = await response.json();
            userInfo = await data.users;
        } catch (error) {
            console.error('Error fetching details:', error);
        }

        var doc = new jsPDF({ putOnlyUsedFonts: true });
        doc.setFontSize(20);
        doc.text("Login Attempt Report", doc.internal.pageSize.getWidth() / 2 - doc.getTextWidth("Login Attempt Report") / 2, 20);
        doc.setFontSize(12);

        if (userInfo) {
            const tableBody = userInfo.map(record => [
                record.user_id,
                record.username,
                record.message,
                record.audit_timestamp,
            ]);
            autoTable(doc, {
                startY: 40,
                head: [['User ID', 'Username', 'Audit Message', 'Timestamp']],
                body: tableBody,
            });
        }
        else{
        doc.setFontSize(20);
        doc.text(`No drivers`, doc.internal.pageSize.getWidth() / 2 - doc.getTextWidth(`No drivers`) / 2, 40);
        }
        var pdfData = doc.output("dataurlstring");
        document.getElementById('pdfFrame').removeAttribute("srcdoc");
        document.getElementById('pdfFrame').src = pdfData;

    };

    return (
        <>
            <Header />
            <div id='reportContainer'>
                <div id='reportBTN'>
                    <button onClick={fetchDriverDetails}><i style={{ fontSize: "35px" }} class="las la-user-friends"></i>
                        <br></br>
                        Driver Audit
                    </button>
                    <button onClick={fetchPasswordAudit}><i style={{ fontSize: "35px" }} class="las la-user-friends"></i>
                        <br></br>
                        Password Change
                    </button>
                    <button onClick={fetchLoginAttemptAudit}><i style={{ fontSize: "35px" }} class="las la-user-friends"></i>
                        <br></br>
                        Login Attempt Audit
                    </button>
                </div>
                <iframe  src="demo_iframe_srcdoc.htm" id="pdfFrame" ></iframe>

            </div>
        </>
    );
}

export default AdminReport;
