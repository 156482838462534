import React, { useState } from 'react';
import Header from '../components/Header';
import Session from '../components/Session';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './Checkout.css';

const Checkout = () => {
  const [cartItems, setCartItems] = useState(Session.getShoppingCart().Cart);

  const navigate = useNavigate();

  // Calculate the total price
  const totalPrice = cartItems.reduce((total, item) => total + item.price, 0);

  const removeFromCart = (itemToRemove) => {
    const updatedCart = cartItems.filter(item => item.catalog_item_id !== itemToRemove.catalog_item_id);
    setCartItems(updatedCart);
    const shoppingCart = Session.getShoppingCart();
    shoppingCart.Cart = updatedCart;
    Session.setShoppingCart(shoppingCart);
  };

  const submitOrder = async () => {
    try {
        const response = await fetch(`${Session.getDomain()}/api/order/orderItems`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                driverId: Session.getDriverUser()?._driverId,
                items: cartItems
            }),
        });
        
        if (response.ok) {
            alert('Order submitted successfully!');
            setCartItems([]);
            const shoppingCart = Session.getShoppingCart();
            shoppingCart.Cart = [];
            Session.setShoppingCart(shoppingCart);
            navigate('/');
        } else {
            alert('There was a problem submitting the order.');
        }
    } catch (error) {
        alert(error);
    }
  };

  return (
    <>
      <Header />
      <div className="checkout-container">
        <h1 className="checkout-title">Checkout</h1>
        <ul className="checkout-list">
          {cartItems.map((item) => (
            <li key={item.catalog_item_id} className="checkout-item">
              <div className="CatalogItem">
                <span className="item-text">
                  <strong>{item.price} Points</strong><br />  {item.item_name}
                </span>
                <img className="item-image" src={item.image_url} alt={item.item_name} />
                <button className="remove-button" onClick={() => removeFromCart(item)}>X</button>
              </div>
            </li>
          ))}
        </ul>
        <div className="total-price">
          <h2>Total: {totalPrice} Points</h2>
        </div>
        <div className="submit-order">
          <Button variant="contained" color="success" onClick={submitOrder}>
            Submit order
          </Button>
        </div>
      </div>
    </>
  );
};

export default Checkout;