import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import ThemeSwitch from '../components/ThemeSwitch';
import Session from '../components/Session';

function OrderHistory() {
    const [OrderData, setOrderData] = useState([]); // Initialize as an empty array
    const userId = Session.getUser()?._userId;

    useEffect(() => {
        const fetchOrderHistory = async () => {
            try {
                const response = await fetch(`${Session.getDomain()}/api/profile/getOrderHistory`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ userID: userId }),
                });

                if (response.ok) {
                    const result = await response.json();
                    setOrderData(result); // Assuming result is an array
                } else {
                    console.error('Failed to fetch order history');
                }
            } catch (error) {
                console.error('Error fetching order history:', error);
            }
        };

        if (userId) {
            fetchOrderHistory();
        }
    }, [userId]);

    return (
        <>
            <Header />
            <div id='orderHistoryContainer'>
                {
                    OrderData.length > 0 ?(  OrderData.map(item => (
                        <div key={item.catalog_item_id} className="orderItem">
                            <h6>{item.item_name}</h6>
                            <img src={item.image_url} alt={item.item_name} />
                            <p>Price: ${item.price}</p>
                            <a href={item.item_url} target="_blank">
                                View Item
                            </a>
                        </div>
                    ))) : 
                    <h2>No orders to see</h2>
            }
            </div>
        </>
    );
}

export default OrderHistory;
