import Header from '../components/Header';
import ThemeSwitch from '../components/ThemeSwitch';
import Session from '../components/Session';
import React, { useState, useEffect } from 'react';

function App() {
  const [displayed, settDisplayed] = useState(false);
  const [text, setText] = useState('');

  const Open = () => {
    settDisplayed(true);
  };

  const Close = () => {
    settDisplayed(false);
  };

  async function updateBanner() {
    var input = document.getElementById("AdminInput").value;
    try {
      const response = await fetch(`${Session.getDomain()}/api/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ message: input })
      });

      const result = await response.json();
      window.location.reload();
    } catch (e) {
      console.error('Error:', e);
    }
  }

  function updateText(e) {
    setText(e);
    document.getElementById("AdminMessage").innerHTML = e;
  }
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    fetch(`${Session.getDomain()}/api/`)
      .then((response) => response.json())
      .then((data) => {
        setText(data.homemessage);
        document.getElementById("AdminMessage").innerHTML = data.homemessage;
      })
      .catch((error) => {
        console.error("API fetch error:", error);
        setLoading(false);
      });
  }, []);

  return (
    <>
      <Header />
      <h1 style={{ textAlign: "center", color: "black", textShadow: "1px 1px 0 white, -1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white" }}>Home page</h1>
      <div id='AdminMessage'></div>

      <div style={{ display: "flex", alignItems: 'center', flexDirection: 'column' }}>
        {Session.getUser()?._accountType == '3' ? (
          <button className='AdminMessageBTN' onClick={Open}>Edit</button>
        ) : (<></>)}

        <div style={{ marginTop: "20px" }}>
          {Session.getUser() && (
            <h2 style={{ textAlign: "center", color: "black", textShadow: "1px 1px 0 white, -1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white" }}>Account: {Session.getUser()._accountType === 1 ? "Driver" : Session.getUser()._accountType === 2 ? "Sponsor" : "Admin"}</h2>
          )}
        </div>

        {displayed && (
          <div id="inputContainer">
            <textarea
              id="AdminInput"
              rows="5"
              cols="30"
              value={text}
              onChange={(e) => updateText(e.target.value)}
            />
            <br />
            <div>
              <button className='AdminMessageBTN' onClick={updateBanner}>Submit</button>
              <button className='AdminMessageBTN' onClick={Close}>Close</button>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default App;
