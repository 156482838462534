class DriverUser {
    constructor(sponsorId, points, driverId) {
        this._sponsorId = sponsorId;
        this._points = points;
        this._driverId = driverId;

    }
    get sponsorId() {
        return this._sponsorId;
    }

    get points() {
        return this._points;
    }

    get driverId() {
        return this._driverId;
    }
}

export default DriverUser;