import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import Session from '../components/Session';
import RewardsPlanCard from '../components/RewardsPlanCard';
import ManageRewardsPlanDriversModal from '../components/ManageRewardsPlanDriversModal';
import AddRewardsPlanModal from '../components/AddRewardsPlanModal';
import RemoveRewardsPlanModal from '../components/RemoveRewardsPlanModal';

import './ManageDriverRewardsPlans.css';

const ManageDriverRewardsPlans = () => {
    const { sponsor_id } = useParams();
    const [sponsor, setSponsor] = useState(null);
    const [rewardsPlans, setRewardsPlans] = useState([]);
    const [selectedRewardsPlan, setSelectedRewardsPlan] = useState(null);
    const [planDrivers, setPlanDrivers] = useState([]);

    const [isAddRewardsPlanModalOpen, setIsAddRewardsPlanModalOpen] = useState(false);
    const [isRemoveRewardsPlanModalOpen, setIsRemoveRewardsPlanModalOpen] = useState(false);

    const [isAddDriverModalOpen, setIsAddDriverModalOpen] = useState(false);
    const [isRemoveDriverModalOpen, setIsRemoveDriverModalOpen] = useState(false);

    const navigate = useNavigate();

    const intervalText = {
        1: 'week',
        2: '2 weeks',
        4: 'month'
    };

    useEffect(() => {
        const fetchSponsorDetails = async () => {
            try {
                const response = await fetch(`${Session.getDomain()}/api/admin/sponsors/${sponsor_id}`);
                const data = await response.json();
                setSponsor(data);
                await fetchRewardsPlans();
            } catch (error) {
                console.error('Error fetching sponsor details:', error);
            }
        };
        fetchSponsorDetails();
    }, [sponsor_id]);

    const fetchRewardsPlans = async () => {
        try {
            const response = await fetch(`${Session.getDomain()}/api/rewardsPlans/${sponsor_id}`);
            const data = await response.json();
            setRewardsPlans(data);
        } catch (error) {
            console.error('Error fetching rewards plans:', error);
        }
    };

    const fetchPlanDrivers = async (plan_id) => {
        try {
            const response = await fetch(`${Session.getDomain()}/api/rewardsPlans/${plan_id}/drivers`);
            const data = await response.json();
            setPlanDrivers(data);
        } catch (error) {
            console.error('Error fetching plan drivers:', error);
        }
    };

    const handleRewardsPlanClick = (plan) => {
        setSelectedRewardsPlan(plan);
        setPlanDrivers([]);
        fetchPlanDrivers(plan.rewards_plan_id);
    };

    const addRewardsPlan = async (newPlan) => {
        try {
            const response = await fetch(`${Session.getDomain()}/api/rewardsPlans/${sponsor_id}/addPlan`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(newPlan),
            });
            if (response.ok) {
                fetchRewardsPlans();
            } else {
                console.error('Failed to add rewards plan');
            }
        } catch (error) {
            console.error('Error adding rewards plan:', error);
        }
    };

    const handleOpenAddDriverModal = () => setIsAddDriverModalOpen(true);
    const handleCloseAddDriverModal = () => setIsAddDriverModalOpen(false);
    const handleOpenRemoveDriverModal = () => setIsRemoveDriverModalOpen(true);
    const handleCloseRemoveDriverModal = () => setIsRemoveDriverModalOpen(false);
    const handleOpenAddRewardsPlanModal = () => setIsAddRewardsPlanModalOpen(true);
    const handleCloseAddRewardsPlanModal = () => setIsAddRewardsPlanModalOpen(false);
    const handleOpenRemoveRewardsPlanModal = () => setIsRemoveRewardsPlanModalOpen(true);
    const handleCloseRemoveRewardsPlanModal = async () => {
        fetchRewardsPlans();
        setIsRemoveRewardsPlanModalOpen(false);
    }

    if (!sponsor) {
        return <div>Loading sponsor details...</div>;
    }

    return (
        <>
            <Header />
            <div className="sponsor-details-container">
                <h1 className="sponsor-name">{sponsor.sponsor_name} Driver Rewards Plans</h1>
                <div className="rewards-plan-buttons-container">
                    <button className="add-rewards-plan-button" onClick={handleOpenAddRewardsPlanModal}>
                        Add Rewards Plan
                    </button>
                    <button className="remove-rewards-plan-button" onClick={handleOpenRemoveRewardsPlanModal}>
                        Remove Rewards Plan
                    </button>
                </div>

                {isAddRewardsPlanModalOpen && (
                    <AddRewardsPlanModal
                        closeModal={handleCloseAddRewardsPlanModal}
                        addRewardsPlan={addRewardsPlan}
                    />
                )}

                {isRemoveRewardsPlanModalOpen && (
                    <RemoveRewardsPlanModal
                        closeModal={handleCloseRemoveRewardsPlanModal}
                        sponsor_id={sponsor_id}
                    />
                )}
                <div className="rewards-plans-section">
                    <div className="rewards-plans-list-container">
                        <div className="rewards-plan-list">
                            {rewardsPlans.map((rewardsPlan) => (
                                <RewardsPlanCard
                                    key={rewardsPlan.rewards_plan_id}
                                    plan={rewardsPlan}
                                    color="lightblue"
                                    onClick={() => handleRewardsPlanClick(rewardsPlan)}
                                />
                            ))}
                        </div>
                    </div>
                    {selectedRewardsPlan && (
                        <div className="plan-drivers-section">
                            <div className="plan-drivers-container">
                                <div className="sponsor-users-header">
                                    {selectedRewardsPlan.point_value} pts / {intervalText[selectedRewardsPlan.payment_interval]}
                                </div>
                                <ul className="plan-drivers-list">
                                    {planDrivers.map((driver) => (
                                        <li className="plan-driver-item" key={driver.user_id}>
                                            {driver.username} - {driver.email}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="plan-driver-buttons">
                                <button className="add-driver-button" onClick={handleOpenAddDriverModal}>
                                    Add Driver
                                </button>
                                <button className="remove-driver-button" onClick={handleOpenRemoveDriverModal}>
                                    Remove Driver
                                </button>
                            </div>
                            {isAddDriverModalOpen && (
                                <ManageRewardsPlanDriversModal
                                    plan_id={selectedRewardsPlan.rewards_plan_id}
                                    type="add"
                                    closeModal={handleCloseAddDriverModal}
                                    refreshDrivers={() => fetchPlanDrivers(selectedRewardsPlan.rewards_plan_id)}
                                />
                            )}
                            {isRemoveDriverModalOpen && (
                                <ManageRewardsPlanDriversModal
                                    plan_id={selectedRewardsPlan.rewards_plan_id}
                                    type="remove"
                                    closeModal={handleCloseRemoveDriverModal}
                                    refreshDrivers={() => fetchPlanDrivers(selectedRewardsPlan.rewards_plan_id)}
                                />
                            )}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default ManageDriverRewardsPlans;
