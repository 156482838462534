import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import Session from '../components/Session';
import './ManageSponsorDrivers.css';

const ManageSponsorDrivers = () => {
    const { sponsor_id } = useParams();
    const [sponsor, setSponsor] = useState(null);
    const [sponsorDrivers, setSponsorDrivers] = useState([]);
    const [isAddDriverModalOpen, setIsAddDriverModalOpen] = useState(false);
    const [isRemoveDriverModalOpen, setIsRemoveDriverModalOpen] = useState(false);

    const [addSearchQuery, setAddSearchQuery] = useState('');
    const [removeSearchQuery, setRemoveSearchQuery] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [selectedUser, setSelectedUser] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchSponsorDetails = async () => {
            try {
                const response = await fetch(`${Session.getDomain()}/api/admin/sponsors/${sponsor_id}`);
                const data = await response.json();
                setSponsor(data);
                await fetchSponsorDrivers();
            } catch (error) {
                console.error('Error fetching sponsor details:', error);
            }
        };
        fetchSponsorDetails();
    }, [sponsor_id]);

    const fetchSponsorDrivers = async () => {
        try {
            const response = await fetch(`${Session.getDomain()}/api/admin/sponsors/${sponsor_id}/SponsorDrivers`);
            const data = await response.json();
            setSponsorDrivers(data);
        } catch (error) {
            console.error('Error fetching sponsor drivers:', error);
        }
    };

    const handleAddSearchChange = async (e) => {
        const value = e.target.value;
        setAddSearchQuery(value);
        setErrorMessage('');
        if (value) {
            try {
                const response = await fetch(`${Session.getDomain()}/api/admin/sponsors/${sponsor_id}/addDriver/search?username=${value}`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setSuggestions(data);
            } catch (error) {
                console.error('Error fetching suggestions:', error);
                setErrorMessage('Error fetching suggestions.');
            }
        } else {
            setSuggestions([]);
        }
    };

    const handleRemoveSearchChange = async (e) => {
        const value = e.target.value;
        setRemoveSearchQuery(value);
        setErrorMessage('');
        if (value) {
            try {
                const response = await fetch(`${Session.getDomain()}/api/admin/sponsors/${sponsor_id}/removeDriver/search?username=${value}`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setSuggestions(data);
            } catch (error) {
                console.error('Error fetching suggestions:', error);
                setErrorMessage('Error fetching suggestions.');
            }
        } else {
            setSuggestions([]);
        }
    };

    const handleUserClick = (user) => {
        setSelectedUser(user);
        setAddSearchQuery(user.username);
        setRemoveSearchQuery(user.username);
        setSuggestions([]);
    };

    const handleAddDriver = async () => {
        if (!selectedUser) {
            setErrorMessage("User not found.");
            return;
        }
        try {
            const response = await fetch(`${Session.getDomain()}/api/admin/sponsors/${sponsor_id}/addDriver`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ user_id: selectedUser.user_id }),
            });
            if (response.ok) {
                setErrorMessage('');
                setAddSearchQuery('');
                setSelectedUser(null);
                await fetchSponsorDrivers();
            } else {
                setErrorMessage("Error adding user.");
            }
        } catch (error) {
            console.error("Error adding user:", error);
            setErrorMessage("Error adding user.");
        }
    };

    const handleOpenAddDriverModal = () => {
        setIsAddDriverModalOpen(true);
        setAddSearchQuery('');
        setSuggestions([]);
        setErrorMessage('');
    };

    const handleCloseAddDriverModal = () => {
        setIsAddDriverModalOpen(false);
        setAddSearchQuery('');
        setSelectedUser(null);
        setSuggestions([]);
    };

    const handleOpenRemoveDriverModal = () => {
        setIsRemoveDriverModalOpen(true);
        setRemoveSearchQuery('');
        setSuggestions([]);
        setErrorMessage('');
    };

    const handleRemoveDriver = async () => {
        if (!selectedUser) {
            setErrorMessage("User not found.");
            return;
        }
        try {
            const response = await fetch(`${Session.getDomain()}/api/admin/sponsors/${sponsor_id}/removeDriver`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ user_id: selectedUser.user_id }),
            });
            if (response.ok) {
                setErrorMessage('');
                setRemoveSearchQuery('');
                setSelectedUser(null);
                await fetchSponsorDrivers();
            } else {
                setErrorMessage("Error removing user.");
            }
        } catch (error) {
            console.error("Error removing user:", error);
            setErrorMessage("Error removing user.");
        }
    };

    const handleCloseRemoveDriverModal = () => {
        setIsRemoveDriverModalOpen(false);
        setRemoveSearchQuery('');
        setSelectedUser(null);
        setSuggestions([]);
    };

    if (!sponsor) {
        return <div>Loading sponsor details...</div>;
    }

    const placeholderImage = 'https://via.placeholder.com/150';

    return (
        <>
            <Header />
            <div className="sponsor-details-container">
                <h1 className="sponsor-name">{sponsor.sponsor_name}</h1>

                <div className="sponsor-users-header">Sponsor Drivers</div>
                <div className="sponsor-users-section">
                    <div className="sponsor-users-list-container">

                        {sponsorDrivers.length > 0 ? (
                            <ul className="sponsor-users-list">

                                {sponsorDrivers.map(user => (
                                    <li key={user.user_id} className="sponsor-user-item">
                                        {user.username} - {user.email}
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <p>No sponsor users found.</p>
                        )}
                    </div>

                    <div className="sponsor-users-actions">
                        <button className="add-sponsor-user-button" onClick={handleOpenAddDriverModal}>Add Driver</button>
                        <button className="remove-sponsor-user-button" onClick={handleOpenRemoveDriverModal}>Remove Driver</button>
                    </div>
                </div>

                {/* Add Sponsor User Modal */}
                {isAddDriverModalOpen && (
                    <div className="modal open">
                        <div className="modal-overlay" onClick={handleCloseAddDriverModal}></div>
                        <div className="modal-content">
                            <button className="modal-close" onClick={handleCloseAddDriverModal}>x</button>
                            <h2>Add Driver</h2>

                            <div className="search-container">
                                <input
                                    type="text"
                                    placeholder="Search username"
                                    value={addSearchQuery}
                                    onChange={handleAddSearchChange}
                                    className="search-input"
                                />
                                {suggestions.length > 0 && (
                                    <ul className="suggestions-dropdown">
                                        {suggestions.map((user) => (
                                            <li key={user.user_id} onClick={() => handleUserClick(user)}>
                                                {user.username}
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                            {errorMessage && <p className="error-message">{errorMessage}</p>}
                            <button onClick={handleAddDriver}>Add Driver</button>
                        </div>
                    </div>
                )}

                {isRemoveDriverModalOpen && (
                    <div className="modal open">
                        <div className="modal-overlay" onClick={handleCloseRemoveDriverModal}></div>
                        <div className="modal-content">
                            <button className="modal-close" onClick={handleCloseRemoveDriverModal}>x</button>
                            <h2>Remove Driver</h2>

                            <div className="search-container">
                                <input
                                    type="text"
                                    placeholder="Search username"
                                    value={removeSearchQuery}
                                    onChange={handleRemoveSearchChange}
                                    className="search-input"
                                />
                                {suggestions.length > 0 && (
                                    <ul className="suggestions-dropdown">
                                        {suggestions.map((user) => (
                                            <li key={user.user_id} onClick={() => handleUserClick(user)}>
                                                {user.username}
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                            {errorMessage && <p className="error-message">{errorMessage}</p>}
                            <button onClick={handleRemoveDriver}>Remove User</button>
                        </div>
                    </div>
                )}
            </div>

        </>
    );
};

export default ManageSponsorDrivers;