import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import Session from '../components/Session';
import LabeledIconButton from '../components/LabeledIconButton';
import { Divider } from '@mui/material';

function Admin() {
    const [backendData, setBackendData] = useState({});
    const [isEditing, setIsEditing] = useState({ username: false, email: false });
    const [formData, setFormData] = useState({ username: '', email: '' });
    const userId = Session.getUser()?._userId;
    const userClass = Session.getUser()?._accountType;
    const [overLay, setOverLay] = useState(false);



    function toggleAnnouncement() {
        if (overLay) {
            setOverLay(false);
            var div = document.getElementById("blurContainer");
            div.classList.toggle("blurDiv");
        }
        else {
            setOverLay(true);
            var div = document.getElementById("blurContainer");
            div.classList.toggle("blurDiv");
        }
    }

    async function sendAnnouncement(e) {
        e.preventDefault();
        var AdminMessage = document.getElementById("AdminMessage").value;

        if (AdminMessage) {
            try {
                fetch(`${Session.getDomain()}/api/CustomAdminMessage`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ message: AdminMessage }),
                });
                alert("Message Sent");
                window.location.reload();
            } catch (error) {
                alert("Error Sending message");
            }
        }

    }

    return (
        <>
            <Header />






            <div id='blurContainer'>
                <div style={{ display: "flex", padding: "30px" }}>

                    <LabeledIconButton
                        label='Admin Audit Logs'
                        icon='folder_open'
                        link='../admin/report'
                    ></LabeledIconButton>


                    <LabeledIconButton
                        label='Manage Sponsor Organizations'
                        icon='storefront'
                        link='../admin/sponsors'
                    >
                    </LabeledIconButton>

                    <LabeledIconButton
                        label='Edit User Accounts'
                        icon='edit'
                        link='../AdminEditPage'
                    ></LabeledIconButton>


                    <LabeledIconButton
                        label='Send Announcement'
                        icon='campaign'
                        onClick={toggleAnnouncement}
                    ></LabeledIconButton>
                </div>
            </div>

            {overLay && (
                <form id="announcementForm" onSubmit={sendAnnouncement} >
                    <button id='closeOverlayAnnouncementBTN' onClick={toggleAnnouncement}><span class="material-symbols-outlined">
                        close
                    </span></button>

                    <h1 className="h3 mb-3 fw-normal">Send Announcement</h1>
                    <div className="form-floating">
                        <input name="message" autoComplete='off' type="text" className="form-control" id="AdminMessage" placeholder='Send Announcement' required />
                        <label htmlFor="floatingInput">Message</label>
                    </div>
                    <button className="btn btn-primary w-100 py-2" type="submit">Submit</button>
                </form>)
            }



        </>
    );
}
export default Admin;

