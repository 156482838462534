import React from 'react';
import logo from '../assets/trucklogo.png';
import Header from '../components/Header';
import { useNavigate } from 'react-router-dom';
import session from '../components/Session';
import ThemeSwitch from '../components/ThemeSwitch';
import User from '../models/user';
import SponsorUser from '../models/sponsoruser';
import DriverUser from '../models/driveruser';

function SignIn() {

  var randomPin = Math.floor(10000 + Math.random() * 90000);
  var userId; 
  var userName;
  var Email;
  var accountType;
  var ProfilePicture;
  var TFA;

  const navigate = useNavigate();

  const authenticate = async (event) => {

    event.preventDefault();
    const form = event.target;
    const formData = new FormData(form);

    try {
      const response = await fetch(`${session.getDomain()}/api/authentication`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(Object.fromEntries(formData.entries()))
      });

      const result = await response.json();

      if (result.found === true) {
        // user is stored on the session
        userId = result.userId; 
        userName = result.username;
        Email = result.email;
        accountType = result.accountType;
        TFA = result.TwoFA;
        ProfilePicture = result.ProfilePicture;

        
        if (result.TwoFA == true) {
          try {
            const response = await fetch(`${session.getDomain()}/api/authentication/SendEmail`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({ pin: randomPin, email: Email })
            });

          }
          catch (e) {
            console.error('Error:', e);
          }

          var codeForm = document.getElementById('codeForm');
          codeForm.classList.remove('hideCodeForm');

          var signForm = document.getElementById('signForm');
          signForm.classList.add('hideCodeForm');
        }
        else {

          const d = new Date();
          //d.setTime(d.getTime() + (1 * 20 * 1000)); 
          d.setTime(d.getTime() + (30 * 24 * 60 * 60 * 1000));
          let expires = "expires=" + d.toUTCString();
          document.cookie = "loggedCookie" + "=" + "true" + ";" + expires + ";path=/";
          





          const user = new User(result.userId, result.username, result.email, result.accountType,result.TwoFA,result.ProfilePicture);
          session.setUser(user);
          session.setLoggedIn(true);
          if (user.accountType === 1) {
            // Get the driver user object.
            try {
              const userResponse = await fetch(`${session.getDomain()}/api/getuserdata/driveruser?userId=${encodeURIComponent(user.userId)}`, {
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json'
                }
              });

              const userResult = await userResponse.json();

              if (userResult.found === true) {
                const driverUser = new DriverUser(userResult.sponsorId, userResult.points, userResult.driverId);
                session.setDriverUser(driverUser);
              }
            }
            catch (e) {
              console.error('Error:', e);
            }
          }
          else if (user.accountType === 2) {
            // Get the sponsor user object.
            try {
              const userResponse = await fetch(`${session.getDomain()}/api/getuserdata/sponsoruser?userId=${encodeURIComponent(user.userId)}`, {
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json'
                }
              });

              const userResult = await userResponse.json();

              if (userResult.found === true) {
                const sponsorUser = new SponsorUser(userResult.sponsorId, userResult.sponsorUserId, userResult.status);
                session.setSponsorUser(sponsorUser);
              }
            }
            catch (e) {
              console.error('Error:', e);
            }

          }
          navigate('/');
        }

      }
      else {
        alert(result.message)
      }

    } catch (e) {
      console.error('Error:', e);
    }

  };

  function check(e) {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);
    var inputPin = Object.fromEntries(formData.entries())

    if (randomPin == inputPin.code) {
      const user = new User(userId, userName, Email, accountType,TFA,ProfilePicture);
      session.setUser(user);
      session.setLoggedIn(true);
      const d = new Date();
      d.setTime(d.getTime() + (30 * 24 * 60 * 60 * 1000));

      let expires = "expires=" + d.toUTCString();
      document.cookie = "loggedCookie" + "=" + "true" + ";" + expires + ";path=/";
      navigate('/');
    }
    else {
      alert("Wrong pin please try again");
      window.location.reload();

    }
  }

  function handleForgotPassword() {
    navigate('/forgotpassword');
  }


  return (
    <>
      <Header />
      <form id="signForm" onSubmit={authenticate}>
        <img className="mb-4" src={logo} alt="Logo" width="72" height="57" />
        <h1 className="h3 mb-3 fw-normal">Please sign in</h1>

        <div className="form-floating">
          <input name="username" autoComplete='off' type="text" className="form-control" id="floatingInput" placeholder="Username" />
          <label htmlFor="floatingInput">User Name</label>
        </div>
        <div className="form-floating">
          <input name="password" type="password" className="form-control" id="floatingPassword" placeholder="Password" 
          required/>
          <label htmlFor="floatingPassword">Password</label>
        </div>

        <div className="form-check text-start my-3">
          <input className="form-check-input" type="checkbox" value="remember-me" id="flexCheckDefault" />
          <label className="form-check-label" htmlFor="flexCheckDefault">
            Remember me
          </label>
        </div>
        <button className="btn btn-primary w-100 py-2" type="submit">Sign in</button>
        <button className="btn btn-link w-100 py-2" type="button" onClick={handleForgotPassword}>Forgot your password?</button>
        <p className="mt-5 mb-3 text-body-secondary">© 2017–2024</p>
      </form>


      <form id="codeForm" className='hideCodeForm' onSubmit={check}>
        <img className="mb-4" src={logo} alt="Logo" width="72" height="57" />
        <h1 className="h5 mb-3 fw-normal">Enter the 5 digit code sent to your email.<br />May take up to 5 minutes, check spam folder.</h1>

        <div className="form-floating">
          <input name="code" autoComplete='off' type="text" className="form-control" id="floatingInput" placeholder="name@example.com" />
          <label htmlFor="floatingInput">5 digit code</label>
        </div>
        <button style={{ marginTop: "10px" }} className="btn btn-primary w-100 py-2" type="submit">Enter</button>
      </form>



    </>

  );
}

export default SignIn;
