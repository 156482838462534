import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import Session from '../components/Session';

import { Autocomplete, TextField } from '@mui/material';


function AdminEditPage() {
    const [users, setUsers] = useState([]);

    const [CurrentUser, setCurrentUser] = useState();

    const [searchInput, setSearchInput] = useState('');



    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await fetch(`${Session.getDomain()}/api/getuserdata/UserData`);
                const data = await response.json();
                setUsers(data);
            } catch (error) {
                console.error('Error fetching sponsors:', error);
            }
        };
        fetchUsers();
    }, []);


    const filteredUsers = users.filter(user =>
        user.username.toLowerCase().includes(searchInput.toLowerCase())
    );



    function setForm(selectedUser) {
        setCurrentUser(selectedUser);
        var UserName = document.getElementById("UserName");
        var Email = document.getElementById("Email");
        var AccountLocked = document.getElementById("AccountLocked");
        var TwoFA = document.getElementById("TwoFA");
        UserName.value = selectedUser.username;
        Email.value = selectedUser.email;
        AccountLocked.value = selectedUser.account_locked || false;
        TwoFA.value = selectedUser.twofa || false;
    }


    const updateUser = async (e) => {
        e.preventDefault();

        const username = document.getElementById('UserName').value;
        const email = document.getElementById('Email').value;
        const password = document.getElementById('Password').value;
        const accountLocked = document.getElementById('AccountLocked').value;
        const twoFA = document.getElementById('TwoFA').value;

        const userData = {
            user_id: CurrentUser.user_id,
            username,
            email,
            password,
            AccountLocked: accountLocked,
            TwoFA: twoFA,
        };

        try {
            const response = await fetch(`${Session.getDomain()}/api/profile/AdminUpdateUserAccount`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(userData),
            });

            if (response.ok) {
                alert("User updated successfully!");
                window.location.reload();
            } else {
                alert("Failed to update user.");
            }
        } catch (error) {
            console.error('Error updating user:', error);
        }
    };



    return (
        <>
            <Header />
            <form id="signForm" onSubmit={updateUser} >
                <h1 className="h3 mb-3 fw-normal">Edit User Account</h1>
                <Autocomplete
                    options={filteredUsers}
                    getOptionLabel={(option) => option.username}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Search by Username"
                            variant="outlined"
                            onChange={(e) => setSearchInput(e.target.value)}
                            fullWidth
                        />
                    )}
                    onChange={(event, selectedUser) => {
                        if (selectedUser) {
                            setForm(selectedUser);
                        }
                    }}
                />

                <div style={{ marginTop: "15px" }} className="form-floating">
                    <input name="username" autoComplete='off' type="text" className="form-control" id="UserName" placeholder="Username" required />
                    <label htmlFor="floatingInput">User Name</label>
                </div>
                <div className="form-floating">
                    <input name="email" autoComplete='off' type="email" className="form-control" id="Email" placeholder="test@test.com" required />
                    <label htmlFor="floatingInput">Email</label>
                </div>
                <div className="form-floating">
                    <input name="password" type="password" className="form-control" id="Password"
                        placeholder="Password" />
                    <label htmlFor="floatingPassword">Edit Password</label>
                </div>

                <div className="form-floating">
                    <select name="AccountLocked" className="form-control" id="AccountLocked" required>
                        <option value="false">False</option>
                        <option value="true">True</option>
                    </select>
                    <label htmlFor="floatingAccountLocked">Account Locked</label>
                </div>

                <div className="form-floating">
                    <select name="TwoFA" className="form-control" id="TwoFA" required>
                        <option value="false">False</option>
                        <option value="true">True</option>
                    </select>
                    <label htmlFor="floatingTwoFA">Two-Factor Authentication</label>
                </div>

                <button className="btn btn-primary w-100 py-2" type="submit">Submit</button>
            </form>



        </>
    );
}

export default AdminEditPage;



